<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        v-if="text"
        class="rich-text"
        :class="{ dark: props.bgDark }"
        v-html="markdownToHtml"
    ></div>
</template>

<script setup>
import { marked } from 'marked';
const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    bgDark: {
        type: Boolean,
        default: false,
    },
});
const markdownToHtml = computed(() => {
    return marked(props.text);
});
</script>

<style lang="scss" scoped>
.rich-text {
    :deep() {
        p,
        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 1.5rem !important;
            color: $brand-primary;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
    :deep() {
        h1 {
            font-size: ($font-size-base * 3.444);
            font-weight: 800;
            line-height: 6rem;
        }
        h2 {
            font-size: ($font-size-base * 2.889);
            line-height: 5rem;
            font-weight: 700;
        }
        h3 {
            font-size: ($font-size-base * 1.5);
            line-height: 2.5rem;
            font-weight: 700;
        }
        h4 {
            font-size: ($font-size-base * 1.311);
            line-height: 2rem;
            font-weight: 700;
        }
        h5 {
            font-size: ($font-size-base * 1.256);
            line-height: 1.5rem;
            font-weight: 700;
        }
        h6 {
            font-size: ($font-size-base * 1.133);
            font-weight: 700;
        }
        ul {
            margin-bottom: 1.5rem;
            li {
                line-height: 1.4;
                position: relative;
                padding-left: 16px;
                margin-bottom: 8px;
                &:before {
                    position: absolute;
                    content: '•';
                    left: 0;
                    margin-right: 12px;
                    color: $brand-primary;
                    font-size: 1rem;
                    line-height: 18px;
                }
            }
        }
        ol {
            margin-bottom: 1.5rem;
            list-style-type: decimal;
            padding-left: 1rem;
            li {
                line-height: 1.4;
                position: relative;
                padding-left: 16px;
                margin-bottom: 8px;
            }
        }
        p .bold,
        strong {
            font-weight: 700;
            color: $brand-primary;
        }
        a {
            font-weight: 500;
            text-decoration: underline;
        }
    }
    &.dark {
        :deep() {
            p,
            div,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 1.5rem !important;
                color: $white;
            }
        }
        :deep() {
            ul {
                margin-bottom: 1.5rem;
                li {
                    line-height: 1.4;
                    position: relative;
                    padding-left: 16px;
                    margin-bottom: 8px;
                    &:before {
                        position: absolute;
                        content: '•';
                        left: 0;
                        margin-right: 12px;
                        color: $white;
                        font-size: 1rem;
                        line-height: 18px;
                    }
                }
            }
            ol {
                margin-bottom: 1.5rem;
                list-style-type: decimal;
                padding-left: 1rem;
                li {
                    line-height: 1.4;
                    position: relative;
                    padding-left: 16px;
                    margin-bottom: 8px;
                    color: $white;
                }
            }
            p .bold,
            strong {
                font-weight: 700;
                color: $white;
            }
            a {
                font-weight: 500;
                text-decoration: underline;
                color: $white;
            }
        }
    }
}
</style>
